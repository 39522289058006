<template>
  <div>
    <b-row>
      <b-col>
        <b-card no-body>
          <b-card-header>
            <div>
              <b-card-title>ข้อมูลการเชื่อมต่อ (Sandbox)</b-card-title>
            </div>
          </b-card-header>
          <hr class="m-0">
          <b-card-body>
            <b-card-text>
              คุณจำเป็นต้องกรอกข้อมูลทางเทคนิค เพื่อขออนุมัติการเชื่อมต่อกับระบบ
              Sandbox คลิกที่ปุ่มด้านล่างเพื่อส่งคำขอ
            </b-card-text>
            <b-button
              variant="primary"
              :to="{ name: 'request_form' }"
            >
              ส่งคำขอเชื่อมต่อ Sandbox
            </b-button>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>

    <b-button
      v-ripple.400="'rgba(40, 199, 111, 0.15)'"
      variant="flat-primary"
    >
      <feather-icon
        icon="LogInIcon"
        class="mr-50"
      />
      <span class="align-middle">เอกสารการเชื่อมต่อ API</span>
    </b-button>
  </div>
</template>

<script>
import Ripple from 'vue-ripple-directive'

export default {
  directives: {
    Ripple,
  },
  components: {},
  props: {
    agentId: {
      type: String,
      default: '',
    },
  },
  data() {
    return {}
  },
}
</script>
